<script setup lang="ts">
import StaffLayout from "@/Layouts/StaffLayout.vue";
import { useChatStore } from "@/stores/chat";
import { tryOnMounted } from "@vueuse/shared";

const chat = useChatStore();

tryOnMounted(() => {
  chat.type = "staff";
});
</script>

<template>
  <StaffLayout title="Berichten">
    <div>
      <div class="container my-15"></div>
    </div>
  </StaffLayout>
</template>
